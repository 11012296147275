import $ from 'jquery';
import qs from 'qs';

export class Login {
    constructor() {
        document.addEventListener('form-gadget-post-submit', function (event) {
            $(event.detail.form).filter(".login-form").each((i,form) => {
                if(event.detail.request.status == 200) {
                    $(event.detail.form).find("button.submit").attr("disabled","disabled");

                    //get the loginRedirect parameter otherwise go to location redirect
                    let location = event.detail.request.getResponseHeader("Location");
                    if(window.location.search && window.location.search.length > 0) {
                        const queryString = qs.parse(window.location.search.substring(1));
                        location = queryString.loginRedirect
                    }
                    window.location = location;
                }
            });
        });
    }
}


