import {decodeAuthCookie} from './AuthCookie';
import $ from 'jquery';

const UPGRADE_MODAL_CONTAINER_ID = 'call-to-upgrade-modal-container';



export const openUpgradeModal = (vanillaModal, response402, teamId, teamOwner) => {

    const teamLink = `/teams/${teamId}/settings.html`;
    const authInfo = decodeAuthCookie();
    const isTeamOwner = !!authInfo && authInfo.email == teamOwner.email;

    let message = "";
    if(!isTeamOwner) {
        message = `<p>Since you're not the team leader, send an email to let them know you need to upgrade: <a href="mailto:${teamOwner.email}">${teamOwner.email}</a></p>`
    } else {
        message = `<a class="button" href="${teamLink}">Upgrade Now</a>`;
    }

    const modalContainer = `<div id="${UPGRADE_MODAL_CONTAINER_ID}" style="display: none;">
        <div class="modal-wrapper call-to-upgrade-modal-wrapper">
            <div class="call-to-upgrade-modal">
                <h2>Whoops, ${response402.message}</h4>
                <p>
                    Upgrade your team plan to take advantage of more integrations and team members.
                </p>
                ${message}
            </div>
        </div>
    </div>`;

    $(`#${UPGRADE_MODAL_CONTAINER_ID}`).remove();

    $("body > .content").append(modalContainer);
    vanillaModal.close();

    vanillaModal.open(`#${UPGRADE_MODAL_CONTAINER_ID}`);

};


