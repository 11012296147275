import Cookies from 'js-cookie';

import base64url from "base64url";

const auth = 'auth';

export const decodeAuthCookie = () => {
    let result = null;
    if(Cookies.get(auth)) {
        try {
            const authObj = Cookies.get(auth).split('.')[1];
            result = JSON.parse(base64url.decode(authObj));
        } catch(ex) {
            console.error(ex);
        }
    }
    return result;
}

