import $ from 'jquery';
import qs from 'qs';

export class Registration {
    constructor() {
        const queryString = qs.parse(window.location.search.substring(1));
        const email = queryString.email;
        $("form.registration-form").find("input[name=email]").val(email);
        document.addEventListener('form-gadget-pre-submit', function (event) {
            $(event.detail.form).filter(".registration-form").each((i,form) => {
                event.detail.data.hasAcceptedTerms = event.detail.data.hasAcceptedTerms && event.detail.data.hasAcceptedTerms == "true";
            });
        });
    }
}


