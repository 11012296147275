import ES6Promise from 'es6-promise';
ES6Promise.polyfill();

import {Modal} from './Modal';
//import {Builder} from './Builder';
import {FormBuilder} from './FormBuilder';

import {Login} from './Login';
import {PublishForm} from './PublishForm';
import {initForms} from './utils/formgadget/FormGadget';
//import {DropDownModal} from './DropDownModal';
import {Registration} from './Registration';
import {ResetPassword} from './ResetPassword';
import {TimezoneOffsetCookie} from './TimezoneOffsetCookie';

import {Submissions} from './Submissions';
import {NestedNavigation} from './NestedNavigation';

import {FormIntegration} from './FormIntegration';
import {CreateIntegration} from './CreateIntegration';

import {FormEditName} from './FormEditName';

import {MemberInvite} from './MemberInvite';
import {MemberDelete} from './MemberDelete';

import {MainMenu} from './MainMenu';

import {IntegrationResultDebugLog} from './IntegrationResultDebugLog';

import {GoogleAnalytics} from './GoogleAnalytics';

import {ErrorPage} from './ErrorPage';

import {Readme} from './Readme';

import {ContactUs} from './ContactUs';

import {VimeoVideos} from './VimeoVideos';

const modal = new Modal();
//new Builder(modal.getVanillaModal());
//new FormBuilder(modal.getVanillaModal());
new FormBuilder();
new Login();
new PublishForm(modal.getVanillaModal());
new Registration();
new ResetPassword(modal.getVanillaModal());
new TimezoneOffsetCookie();
//new DropDownModal();

new Submissions();
new IntegrationResultDebugLog();

new MainMenu();
new NestedNavigation();

new FormIntegration();
new CreateIntegration(modal.getVanillaModal());

new FormEditName();
new MemberInvite(modal.getVanillaModal());
new MemberDelete();

new GoogleAnalytics();

new ErrorPage();

new Readme();
new ContactUs();

new VimeoVideos();

initForms();