import $ from 'jquery';


const OPEN = "open";
const NESTED_MAIN_MENU_SELECTOR = ".nested-main-menu";
const NESTED_MAIN_MENU_LINK_SELECTOR = `${NESTED_MAIN_MENU_SELECTOR} > a`;
const MOBILE_MENU_FORMS_LINK_SELECTOR = `${NESTED_MAIN_MENU_SELECTOR}.forms > a`;
const FORM_NAVIGATION_WRAPPER_SELECTOR = '.left.rail .form-navigation-wrapper';


const toggleClass = (nestedMenu, open) => {
    console.log("nestedMenu");
    console.log(nestedMenu);
    //const $nestedMenu = $(nestedMenuLink).parents(NESTED_MAIN_MENU_SELECTOR).first();
    const $nestedMenu = $(nestedMenu);
    if(open) {
        $nestedMenu.addClass(OPEN);
    } else {
        $nestedMenu.removeClass(OPEN);
    }
};

const toggleNestedMenu = (nestedMenuLink) => {

    const $nestedMenu = $(nestedMenuLink).parents(NESTED_MAIN_MENU_SELECTOR).first();

    if($nestedMenu.hasClass(OPEN)) {
        toggleClass($nestedMenu, false);
    } else {
        toggleClass($nestedMenu, true);
    }


};

const copyLeftHandMenuStructure = (mobileMenuFormsElement) => {
    $(mobileMenuFormsElement).parent().append($(FORM_NAVIGATION_WRAPPER_SELECTOR).clone());
};

export class MainMenu {
    constructor() {
        $(MOBILE_MENU_FORMS_LINK_SELECTOR).first().each((i,e) => {
            copyLeftHandMenuStructure(e);
        });

        $(NESTED_MAIN_MENU_LINK_SELECTOR).on("click", (event) => {
            event.preventDefault();
            event.currentTarget;
            toggleNestedMenu(event.currentTarget);
        });

        $(NESTED_MAIN_MENU_SELECTOR).hover(
            (eventIn) => toggleClass(eventIn.currentTarget, true),
            (eventOut) => toggleClass(eventOut.currentTarget, false)
        );

        //$(MOBILE_MENU_FORMS_LINK_SELECTOR).trigger('click');

    }
}
