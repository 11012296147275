import jQuery from 'jquery'

/*
https://gist.github.com/andrewbranch/6995056
https://gist.github.com/samelwitt/3d9d67898a7f357f1a5833b7b8199ff8
https://stackoverflow.com/questions/6406843/detect-if-text-has-overflown
*/

const resize = (e) => {
    console.log(e);
    const textWidthCalculator = (text, fontProp) => {
        var tag = document.createElement("div");
        tag.style.position = "absolute";
        tag.style.left = "-999em";
        tag.style.whiteSpace = "nowrap";
        tag.style.font = fontProp;
        tag.innerHTML = text;
        document.body.appendChild(tag);
        var result = tag.clientWidth;
        document.body.removeChild(tag);
        return result;
    }
    const $e = jQuery(e);
    const innerWidth = $e.innerWidth() - 20; //20 is a nice offset
    const text = $e.text();

    let textWidth = 0;
    let fontSize = 20;
    do {
        fontSize = fontSize - 2;
        textWidth = textWidthCalculator(text, fontSize + 'px arial,serif');
    } while(textWidth > innerWidth)

    $e.css({ 'font-size': fontSize + 'px'});
    console.log("setting text width to: " + fontSize + " for: " + text + " textWidth:" + textWidth + " innerWidth: " + innerWidth);

};

export default function() {
    var that = this;
    +function($) {
        return that.each(function(i,e) {
            resize(e);

            $(window).on("resize", function() {
                resize(e);
            });
        });
    }(jQuery)
}