import $ from 'jquery';

const setupFormEditNameSubmitListener = (editor) => {
    document.addEventListener('form-gadget-pre-submit', function (event) {
        $(event.detail.form).filter(".form-edit-name").each((i,form) => {
            event.detail.data = event.detail.data.name;
        });
    }, false);
}

export class FormEditName {
    constructor() {
        setupFormEditNameSubmitListener();
    }
}

