import $ from 'jquery';
import qs from 'qs';
import {decodeAuthCookie} from './utils/AuthCookie';

const updateSubjectField = () => {
    $("body.contact-us form.form-gadget").each((i,form) => {
        const $form = $(form);
        const authCookie = decodeAuthCookie();

        if(authCookie) {
            $form.find("input[name=firstName]").val(authCookie.firstName);
            $form.find("input[name=lastName]").val(authCookie.lastName);
            $form.find("input[name=email]").val(authCookie.email);
        }

        if(window.location.search && window.location.search.length > 0) {
            const queryString = qs.parse(window.location.search.substring(1));
            const subject = queryString.subject;
            $form.find("select[name=subject]").val(subject);
        }

    });
};

export class ContactUs {

    constructor() {
        document.addEventListener('form-gadget-post-form-loaded', function(event) {
//            console.log("post-form-loaded");
            updateSubjectField();
        });
        updateSubjectField();

        document.addEventListener('form-gadget-post-submit', function(event) {
            $(event.detail.form).filter(".contact-us").each((i,form) => {
                if(event.detail.request.status == 201) {
                    const $form = $(form);
                    console.log("$form");
                    console.log($form);
                    const $container = $(form).parents(".contact-form");

                    $container.children().hide();
                    $container.find(".thank-you-message").show();
                }
            });
        });
    }


}