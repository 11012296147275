import $ from 'jquery';
import base64url from "base64url";

export class MemberDelete {
    constructor() {
        document.addEventListener('form-gadget-pre-submit', function (event) {
            $(event.detail.form).filter(".delete-member").each((i,form) => {
                const base64Email = base64url(event.detail.data.email);
                const url = event.detail.form.action.replace("%7Bbase64Email%7D", base64Email);
                event.detail.form.action = url;
                event.detail.data = {};
            });
        });
    }
}


