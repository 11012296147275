import $ from 'jquery';

export class Readme {
    constructor() {
        $("body.readme .readme-content .readme .title").on('click', (event) => {
            event.preventDefault();
            const $title = $(event.currentTarget);
            const $textBody = $title.siblings('.text-body');
            $textBody.toggle();
            if($textBody.is(':visible')) {
                $title.addClass('open');
            } else {
                $title.removeClass('open');
            }
        });
    }
}

