
import {fetchGet} from './FetchUtil';

export function getValidation(formId, formIntegrationId) {
    return fetchGet('/v1/forms/' + formId + '/integrations/' + formIntegrationId + '/valid');
}

export function getIntegrationDefinitions(formId, implemented) {
    return fetchGet('/v1/integration-definitions?formId=' + formId + '&implemented=' + implemented);
}

