import $ from 'jquery';
import {get} from './api/FormSubmissionAPI';
import {HandsontableService} from './HandsontableService';

export class Submissions {
    constructor() {
        $("#submissions-table-wrapper").each((i,e) => {
            new HandsontableService(e, get);
        });
    }
}
