import $ from 'jquery';
import {get} from './api/FormSubmissionAPI';

const Handsontable = global.Handsontable;

const updateParentSizeForTableWrapper = (autoColumnSizePlugin, element) => {

    const $tableWrapper = $(element);
    const padding = 50*2;
    const border = 1*2; //1px * 2 sides
    const hotWidth = $tableWrapper.find(".wtHider").first().width();

    const minTableWidth = hotWidth + padding + border;

    const $submissions = $tableWrapper.parents(".submissions").first();

    $submissions.css({
        minWidth: `${minTableWidth}px`
    });
    //parent needs to be 10% larger than $submissions
    const parentMinWidth = minTableWidth*10/9;
    $submissions.parent().css({
        minWidth: `${parentMinWidth}px`
    });
    $submissions.parents(".center").css({
        minWidth: `${parentMinWidth}px`
    });
    //need to update the content width as well.
    const $content = $submissions.parents(".content");
    const leftRailWidth = $content.find("> .left.rail").first().width();
    const contentWidth = parentMinWidth + leftRailWidth;
    $content.css({
        minWidth: `${contentWidth}px`
    });
    //need to update the navigation
    $content.siblings(".main-menu-wrapper").css({
        minWidth: `${contentWidth}px`
    });


};

const generateRowsAndHeadersFromRows = (response, existingHeaders) => {
    const result = {rows: [], headers: existingHeaders};

    result.rows = response.map((obj) => {
        const row = [];
        for (var prop in obj) {
            // skip loop if the property is from prototype
            if(!obj.hasOwnProperty(prop)) continue;
            if(!!prop && result.headers.indexOf(prop) < 0) {
                result.headers.push(prop);
            }
        }

        for (let header of result.headers) {
            row.push(obj[header]);
        }
        return row;
    });

    return result;
};

const pageSize = 25;

export class HandsontableService {
    constructor(tableWrapper, apiGet) {
        $(tableWrapper).each((i,e) => {
            const formId = e.dataset.formid;

            const $loadMoreButton = $(e).siblings(".load-more");

            const hotInstance = new Handsontable(e, {
              data: [],
              rowHeaders: false,
              readOnly: true,
              stretchH: 'all',
              autoColumnSize: true,
            });


            const autoColumnSizePlugin = hotInstance.getPlugin('autoColumnSize');
            const updateParentSize = () => {updateParentSizeForTableWrapper(autoColumnSizePlugin, e);};
            if (autoColumnSizePlugin.isEnabled()) {
              $( window ).resize(() => {updateParentSize(); });
            }

            const existingHeaders = [];
            $loadMoreButton.on('click', (event) => {
                const $button = $(event.currentTarget);
                $button.attr("disabled","disabled");

                const firstId = $button.data('firstid');

                //here we load the next page.
                apiGet(formId, firstId, pageSize).then((response) => {
                    if(response.length > 0){
                        const sanitizedRows = response.map(x => x[1]);
                        const {rows, headers} = generateRowsAndHeadersFromRows(sanitizedRows, existingHeaders );
                        existingHeaders.concat(headers);
                        const allHeaders = headers;
                        hotInstance.updateSettings({
                          colHeaders: allHeaders
                        });

                        const allRows = hotInstance.getData().concat(rows);
                        let firstRow = allRows.length > 0 ? allRows.shift() : [];

                        if(firstRow.length < allHeaders.length) {
                            for(i = 0; i <= allHeaders.length - firstRow.length; i++){
                                firstRow.push("");
                            }
                        }

                        const data = [firstRow].concat(allRows);
                        hotInstance.loadData(data);

                        $button.data('firstid', response[response.length - 1][0]);
                    }
                    if(response.length == pageSize) {
                        $button.removeAttr("disabled");
                        $button.css('visibility', 'visible');
                    } else {
                        $button.css('visibility', 'hidden');
                        if(!firstId && response.length == 0) {
                            //no results
                            $button.siblings(".no-results").show();
                        }
                    }
                    updateParentSize();

                });

            });
            $loadMoreButton.trigger('click');
        });
    }
}
