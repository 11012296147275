import $ from 'jquery';
import {get} from './api/FormIntegrationResultAPI';
import {HandsontableService} from './HandsontableService';

export class IntegrationResultDebugLog {
    constructor() {
        $("#debug-log-table-wrapper").each((i,e) => {
            new HandsontableService(e, get);
        });
    }
}
