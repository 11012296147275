
import $ from 'jquery';

/*
import * as ace from 'brace';
import 'brace/mode/javascript';
import 'brace/theme/twilight';
*/

//const ace = global.ace;

const setupFormSubmitListener = (editor) => {
    document.addEventListener('form-gadget-pre-submit', function (event) {
        $(event.detail.form).filter(".form-editor").each((i,form) => {
            event.detail.data = editor.getValue();
        });
    }, false);
}

const disableAceDoctypeWarning = (editor) => {
    const session = editor.getSession();
    session.on("changeAnnotation", () => {
      const annotations = session.getAnnotations()||[];
      let i = annotations.length;
      let len = annotations.length;
      while (i--) {
        if(/doctype first\. Expected/.test(annotations[i].text)) {
          annotations.splice(i, 1);
        }
      }
      if(len > annotations.length) {
        session.setAnnotations(annotations);
      }
    });
}

const formGadgetCompleter = {
    getCompletions: function(editor, session, pos, prefix, callback) {
        const wordList = ["data-validate", "required", "email"];
        callback(null, wordList.map(function(word) {
            return {
                caption: word,
                value: word,
                meta: "formgadget"
            };
        }));

    }
}


export class FormBuilder {
    constructor() {

        $(".html-editor").each((i,htmlEditor) => {

            const $htmlEditor = $(htmlEditor);
            const editor = ace.edit($htmlEditor.attr('id'));
            editor.session.setMode("ace/mode/html");
            editor.setTheme("ace/theme/eclipse");
            editor.setHighlightActiveLine(true);
            editor.setShowPrintMargin(false);
            editor.setOptions({
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                maxLines: Infinity //this makes sure that we don't get a double scroll bar when the nav is long and the form is long
            });

            if($htmlEditor.data('readonly')) {
                editor.setReadOnly(true);
            }
            if($htmlEditor.data('wordwrap')) {
                editor.getSession().setUseWrapMode(true);
            }
            editor.completers.pop();
            editor.completers.unshift(formGadgetCompleter);
            disableAceDoctypeWarning(editor);


            setupFormSubmitListener(editor);


        });
    }
}



