import $ from 'jquery';

export class ResetPassword {
    constructor(vanillaModal) {
        document.addEventListener('form-gadget-post-submit', function (event) {
            $(event.detail.form).filter(".password-reset").each((i,form) => {
                const $form = $(form);
                $form.hide();
                $form.parent().find(".password-reset-success").show();
            });
        });

        document.addEventListener('form-gadget-post-submit', function (event) {
            $(event.detail.form).filter(".settings-password-update").each((i,form) => {
                console.log("form for settings password update");
                console.log(form);
                if(event.detail.request.status == 200) {
                    vanillaModal.close();
                } else {
                    if(event.detail.request.status == 403){
                        console.log(event.detail.response);
                        $(form).find(".form-gadget-form-error").html("");
                        $(form).find("button.submit").before(`<div class='formgadget-error'>wrong password entered.</div>`);
                    } else {
                        $(form).find("button.submit").before(`<div class='formgadget-error'>${event.detail.response.message}</div>`);
                    }
                }
            });
        });

    }

}


