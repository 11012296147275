import VanillaModal from 'vanilla-modal';
import $ from 'jquery';
import bowser from 'bowser';

import {initForms} from './utils/formgadget/FormGadget';

import {StripePayment} from './StripePayment';

const isMobile = () => !!bowser.ios || !!bowser.mobile;

const NOT_MODAL_SELECTOR = "body > *:not(.modal):not(script)";

export class Modal {

    constructor(vanillaModal) {
        if(isMobile()) {
            $('body').addClass('mobile-detected');
        }

        const onClose = (event) => {
            if(isMobile()) {
                $(NOT_MODAL_SELECTOR).show();
            }
        };



        if($(".modal").length > 0) {
            this.vanillaModal = new VanillaModal({ onOpen: this.onOpen, onClose: onClose, transitions: false});
        }

    }

    getVanillaModal() {
        return this.vanillaModal;
    }

    onOpen(event) {
        console.log("on open event");
        if(isMobile()) {
            $(NOT_MODAL_SELECTOR).hide();
        }
        initForms();
        new StripePayment();
    }

}