import $ from 'jquery';
import qs from 'qs';

import {getIntegrationDefinitions} from './api/FormIntegrationAPI';
import {initForms} from './utils/formgadget/FormGadget';

import {openUpgradeModal} from './utils/UpgradeModal';
import {getTeamOwner} from './api/TeamAPI';


const CREATE_INTEGRATION_DEFINITION = "create-integration-definition";
const CALL_TO_UPGRADE_MODAL_CONTAINER_SELECTOR = '#call-to-upgrade-modal-container';
const UPGRADE_MESSAGE_HEADLINE_SELECTOR = `${CALL_TO_UPGRADE_MODAL_CONTAINER_SELECTOR} .upgrade-message-headline`;


const addIntegrationDefinition = (teamId, formId, integrationDefinition) => {

    return `<div class="form-integration">
                <img src="${integrationDefinition.logoUrl}" alt="${integrationDefinition.name}">
                <h3>${integrationDefinition.name}</h3>
                <p>${integrationDefinition.description}</p>
                <form class="form-gadget ${CREATE_INTEGRATION_DEFINITION}"
                      action="/v1/forms/${formId}/integrations"
                      data-successurl="/forms/${formId}/integrations/{id}.html"
                      data-teamid="${teamId}"
                      method="post">
                    <input type="hidden" name="formId" value="${formId}" />
                    <input type="hidden" name="mapping" value="" />
                    <input type="hidden" name="integrationDefinitionId" value="${integrationDefinition.id}" />
                    <button class="submit">
                        <span>Create</span>
                    </button>
                </form>
            </div>`;
}

const addIntegrationDefinitionsToCreateModal = (teamId, formId) => {
    const implemented = false;
    getIntegrationDefinitions(formId, implemented).then((response) => {
        $(".create-integrations-modal-wrapper .integration-definitions").each((i,e) => {
            const $e = $(e);
            const LOADING = "loading";
            $e.empty();
            $e.addClass(LOADING);
            response.forEach((integrationDefinition) => {
                $e.append(addIntegrationDefinition(teamId, formId, integrationDefinition));
            });
            $e.append(`<div class="additional-integrations"><h3>Looking for a different integration</h3> <a href='/contact-us.html?subject=Integration%20Request' target="_top" class="button">Contact Us</a></div>`);
            $e.removeClass(LOADING);
            initForms("form.create-integration-definition");
        });
    });
}

export class CreateIntegration {
    constructor(vanillaModal) {

        $(".create-integration-wrapper a").on('click', (event) => {
            const formId = event.currentTarget.dataset.formid;
            const teamId = event.currentTarget.dataset.teamid;
            addIntegrationDefinitionsToCreateModal(teamId, formId);
        });

        document.addEventListener('form-gadget-post-submit', function (event) {

            $(event.detail.form).filter(`.${CREATE_INTEGRATION_DEFINITION}`).each((i,form) => {
                if(event.detail.request.status == 402) {
                    const teamId = event.detail.form.dataset.teamid;
                    getTeamOwner(teamId).then((teamOwner) => {
                        //need to close the current modal, and open up a new modal with the message we want.
                        openUpgradeModal(vanillaModal, event.detail.response, teamId, teamOwner);
                    });
                }
            });
        });
    }
}


