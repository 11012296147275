import $ from 'jquery';
import base64url from "base64url";

const INVITE_MEMBER_FORM_SELECTOR = ".invite-member";

import {openUpgradeModal} from './utils/UpgradeModal';
import {getTeamOwner} from './api/TeamAPI';

export class MemberInvite {
    constructor(vanillaModal) {
        console.log("constructing member");
        document.addEventListener('form-gadget-pre-submit', function (event) {
            $(event.detail.form).filter(INVITE_MEMBER_FORM_SELECTOR).each((i,form) => {
                const base64Email = base64url(event.detail.data.email);
                const url = event.detail.form.action.replace("%7Bbase64Email%7D", base64Email);
                event.detail.form.action = url;
                event.detail.data = {};
            });
        });

        document.addEventListener('form-gadget-post-submit', function (event) {
            $(event.detail.form).filter(INVITE_MEMBER_FORM_SELECTOR).each((i,form) => {
                if(event.detail.request.status == 402) {
                    const teamId = event.detail.form.dataset.teamid;
                    getTeamOwner(teamId).then((teamOwner) => {
                        //need to close the current modal, and open up a new modal with the message we want.
                        openUpgradeModal(vanillaModal, event.detail.response, teamId, teamOwner);
                    });
                }
            });
        });
    }
}


