import {forEachElement, addClass, removeClass, closest, ajax, dispatchCustomEvent} from './Utilities';
import {validateForm, PRE_FIRST_SUBMISSION, changeSubmitDisabled} from './Validators';



export function getAllFormFieldsAsDataObject(form) {
    var data = {};

    forEachElement(form, 'input, textarea, select', function(element,i) {
//        console.log("element.type: ", element.type);
        switch(element.type) {
            case "checkbox":
                if(element.checked) {
                    var checkboxArray = data[element.name] ? data[element.name] : [];
                    checkboxArray.push(element.value);
                    data[element.name] = checkboxArray;
                }
                break;

            case "radio":
                var name = element.name;
                forEachElement(document, 'input[name="'+ element.name +'"]', function(item) {
                    if(item.checked) {
                        data[element.name] = item.value;
                    }
                });
                break;
            case "number":
                data[element.name] = parseFloat(element.value);
                break;
            case "submit":
                break;
            default:
                data[element.name] = element.value;
        }

    });
    return data;
}

function validateFormWithEvent(formElement) {
    var isFormValid = validateForm(formElement);
    var detail = { form: formElement, isFormValid: isFormValid };
    dispatchCustomEvent(document, "form-gadget-post-validate", detail);
    changeSubmitDisabled(formElement, detail.isFormValid);
    return detail.isFormValid;
};

export function handleFormSubmit(event) {

    function changeButtonState(button, disabled) {
        button.disabled = disabled;
        if(disabled) {
            addClass(button, "ajax");
        } else {
            removeClass(button, "ajax")
        }
    }

    event.preventDefault();

    //if the form isn't valid then we're not going to do anything.
    var button = event.currentTarget;
    var formElement = closest(button, "form");
    formElement.removeAttribute(PRE_FIRST_SUBMISSION);
    if(!validateFormWithEvent(formElement)) {
        return;
    }

    var formGadgetFormErrorClass = 'form-gadget-form-error';

    forEachElement(formElement, '.' + formGadgetFormErrorClass, function(item) {
        item.style.visibility = "hidden";
    });
    changeButtonState(button, true);

    var detail = { form: formElement, data: getAllFormFieldsAsDataObject(formElement) }
    dispatchCustomEvent(document, 'form-gadget-pre-submit', detail);

    if(!detail.form.action) {
        throw "form does not have an action: " + String(detail.form);
    }

    var method = detail.form.getAttribute("method");
    method = !!method ? method : 'POST';

    ajax(detail.form.action, method, detail.data,
        function(request, response) {
            var obj = JSON.parse(response);
            var successUrl = detail.form.dataset.successurl;
            if(!!successUrl) {
                for (var key in obj) {
                  if (obj.hasOwnProperty(key)) {
                    //console.log(key + " -> " + obj[key]);
                    successUrl = successUrl.replace("{" + key + "}", obj[key]);
                  }
                }
                window.location = successUrl;
            }
        },
        function(request, response) {
            console.error("request to form gadget failed.: " + response);
            forEachElement(detail.form, '.' + formGadgetFormErrorClass, function(item) {
                item.style.visibility = "visible";
                //console.log(typeof response);
                try {
                    var obj = JSON.parse(response);
                    if(Array.isArray(obj)) {
                        var errorMessages = "";
                        for (let i=0; i < obj.length; i++) {
                          var errorItem = obj[i];
                          if(errorItem.validator && errorItem.name) {
                              errorMessages += "Invalid field " + errorItem.name + ". Validation " + errorItem.validator + " failed, ";
                          }
                        }
                        item.innerHTML = errorMessages.substring(0, errorMessages.length - 2);

                    } else if(obj.message && obj.id) {
                        item.innerHTML = obj.message;
                        item.setAttribute('data-errorNumber', obj.id);
                    } else if(obj.message) {
                        item.innerHTML = obj.message;
                    } else {
                        item.innerHTML = response;
                    }
                } catch (e) {
                    item.innerHTML = response;
                }

            });
        },
        function(request, response, isSuccess) {
            detail.request = request;
            /*
            console.log("finally");
            console.log(request);
            console.log(response);
            console.log(isSuccess);
            */
            detail.response = JSON.parse(response);

            //only change the button state if it's not successful or there is no success url
            //otherwise the user is about to be redirected.
            if(!isSuccess || (isSuccess && !detail.form.dataset.successurl)) {
                changeButtonState(button, false);
            }

            dispatchCustomEvent(document, 'form-gadget-post-submit', detail);
        }
    );
}


export function initForms(formSelector) {

    function setupSubmit(formElement) {
        formElement.removeEventListener("submit", handleFormSubmit);
        formElement.addEventListener("submit", handleFormSubmit);
    }

    function setupValidate(formElement) {
        forEachElement(formElement,'[data-validate]',
            function(e, i) {
                formElement.setAttribute(PRE_FIRST_SUBMISSION, "true");
                /*
                console.log("setupValidate: formElement");
                console.log(formElement);
                console.log("adding listener on input event and not change event");
                */

                if(!!e.tagName && e.tagName.toLowerCase() == 'input' && e.type == 'checkbox') {
                    e.addEventListener("click", function(event) {
                        validateFormWithEvent(formElement);
                    });
                } else {
                    e.addEventListener("input", function(event) {
                        validateFormWithEvent(formElement);
                    });
                }
            }
        );

        document.addEventListener('form-gadget-validate-form',
            function(event) {
                if( !event || !event.detail || !event.detail.form ) {
                    console.warn("event 'form-gadget-validate' failed. Event object must contain the form element at event.detail.form", event);
                    return;
                }
                if(formElement != event.detail.form) {
                    console.warn("formElement != match event.detail.form", formElement, event.detail.form);
                    return;
                }
                validateFormWithEvent(formElement);
            }
        );

    }

    function setup(formSelector) {
        var initDataAttributeName = 'data-formgadgetinitialized';
        forEachElement(document, formSelector, function(formElement, i) {
            if(!formElement.getAttribute(initDataAttributeName)) {
                formElement.setAttribute(initDataAttributeName, "true");
                setupSubmit(formElement);
                setupValidate(formElement);
            }
        });

    }

//    console.log("hello johann, we're calling this from FormGadget.js");

    var selector = !!formSelector ? formSelector : "form.form-gadget";
    setup(selector);

}

