import {decodeAuthCookie} from './utils/AuthCookie';
import qs from 'qs';

export class GoogleAnalytics {
    constructor() {
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', global.googleAnalyticsId, 'auto');

        const authCookie = decodeAuthCookie();

        if(authCookie && authCookie.userId) {
            ga('set', 'userId', authCookie.userId);
            ga('set', 'dimension1', authCookie.userId);
        }

        if(window.location.search && window.location.search.length > 0) {
            const queryString = qs.parse(window.location.search.substring(1));
            const tn = queryString.tn;
            if(!!tn) {
                ga('set', 'dimension2', tn);
            }
        }




        ga('send', 'pageview');
    }
}



