import $ from 'jquery';
import {ajax} from './utils/formgadget/Utilities';
import {getAllFormFieldsAsDataObject} from './utils/formgadget/FormGadget';

import {validRequired, INVALID_REQUIRED_CLASS} from './utils/formgadget/Validators';

const Stripe = global.Stripe;
const STRIPE_PAYMENT_FORM_SELECTOR = ".modal-content .payment-information-form";

const AJAX_CLASSNAME = "ajax";

const stripeTokenHandler = (form, token) => {

  // Insert the token ID into the form so it gets submitted to the server
  const hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', 'stripeToken');
  hiddenInput.setAttribute('value', token.id);
  form.appendChild(hiddenInput);

  // Submit the form
  const data = {stripeToken: token.id};
  ajax(form.action, 'POST', data,
    function(request, response) {
        window.location.reload(true);
     },
     function(request, response) {
         try {
            const obj = JSON.parse(response);
             if(!!obj.message) {
                setOutcome(form, {error: { message: obj.message }});
             } else {
                setOutcome(form, {error: { message: response }});
             }
         } catch(ex) {
            setOutcome(form, {error: { message: response }});
         }
         //need to re-enable the form submit button
         const $submitButton = $(form).find("button.submit");
         $submitButton.removeAttr('disabled');
         $submitButton.removeClass(AJAX_CLASSNAME);
     });
};


function setOutcome(form, result) {
  var errorElement = form.querySelector('.error');
  errorElement.classList.remove('visible');

  if (result.token) {
    $(form).find("button.submit").attr('disabled', 'true');
    stripeTokenHandler(form, result.token);
  } else if (result.error) {
    errorElement.textContent = result.error.message;
    errorElement.classList.add('visible');
  }
}

const setBrandIcon = (brand, brandIconId) => {
  var brandIconElement = document.getElementById(brandIconId);
  var pfClass = 'pf-credit-card';

  const cardBrandToPfClass = {
  	'visa': 'pf-visa',
    'mastercard': 'pf-mastercard',
    'amex': 'pf-american-express',
    'discover': 'pf-discover',
    'diners': 'pf-diners',
    'jcb': 'pf-jcb',
    'unknown': 'pf-credit-card',
  }

  if (brand in cardBrandToPfClass) {
  	pfClass = cardBrandToPfClass[brand];
  }
  for (var i = brandIconElement.classList.length - 1; i >= 0; i--) {
  	brandIconElement.classList.remove(brandIconElement.classList[i]);
  }
  brandIconElement.classList.add('pf');
  brandIconElement.classList.add(pfClass);
}

const style = {
  base: {

    lineHeight: '50px',
    iconColor: '#666EE8',
    color: 'black',
    fontWeight: 400,
    fontSize: '16px',

    '::placeholder': {
      color: '#CFD7E0',
    },
  },
  invalid: {
    borderColor: 'red',
  },
};


export class StripePayment {
    constructor() {
//        console.log("running stripe payment constructor");
        $(STRIPE_PAYMENT_FORM_SELECTOR).each((i,form) => {

            const planId = form.dataset.planid;

            const nameId =         `name-for-plan-${planId}`;
            const cardNumberId =  `card-number-for-plan-${planId}`;
            const cardExpiryId =  `card-expiry-for-plan-${planId}`;
            const cardCVCId =     `card-cvc-for-plan-${planId}`;
            const brandIconId =   `brand-icon-for-plan-${planId}`;
            const zipId =         `zip-for-plan-${planId}`;


            const stripe = Stripe(global.stripeAPIKey);
            const elements = stripe.elements();

            var cardNumberElement = elements.create('cardNumber', {
              style: style
            });
            cardNumberElement.mount(`#${cardNumberId}`);

            var cardExpiryElement = elements.create('cardExpiry', {
              style: style
            });
            cardExpiryElement.mount(`#${cardExpiryId}`);

            var cardCvcElement = elements.create('cardCvc', {
              style: style
            });
            cardCvcElement.mount(`#${cardCVCId}`);

            cardNumberElement.on('change', function(event) {
                // Switch brand logo
                if (event.brand) {
                    setBrandIcon(event.brand, brandIconId);
                }
            });

            $(form).find('input').on('input', function(event){
                const inputElement = event.currentTarget;
                validRequired(event.currentTarget) ? $(inputElement).removeClass(INVALID_REQUIRED_CLASS) : $(inputElement).addClass(INVALID_REQUIRED_CLASS);
            });

            form.addEventListener('submit', function(e) {
              e.preventDefault();

              //here we validated to make sure each field has been filled in, if it hasn't we add the class
              //invalid-required
              //loop through the form fields and make sure they all have a value in them.
//              console.log("adding ajax class to submit button");
//              console.log(e);
              const $submitButton = $(form).find(".submit");
              $submitButton.addClass(AJAX_CLASSNAME);
              $(form).find('input').each((i,e) => {
                if(!validRequired(e)){
                    $(e).addClass(INVALID_REQUIRED_CLASS);
                }
              });

              var options = {
                name: document.getElementById(nameId).value,
                address_zip: document.getElementById(zipId).value
              };
              stripe.createToken(cardNumberElement, options).then((result) => setOutcome(form, result));
            });

        });
    }
}


