import $ from 'jquery';

const OPEN = "open";
const CLOSED = "closed";

export class NestedNavigation {
    constructor() {
        $(`.sub-navigation-wrapper .${OPEN} > a, .sub-navigation-wrapper .${CLOSED} > a`).on('click', (event) => {
            event.preventDefault();
            const $e = $(event.currentTarget).parent();
            if($e.hasClass(OPEN)) {
                $e.addClass(CLOSED);
                $e.removeClass(OPEN);
            } else {
                $e.addClass(OPEN);
                $e.removeClass(CLOSED);
            }
        });
    }
}


