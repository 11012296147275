
import 'whatwg-fetch';

import qs from 'qs';

export const createUrl = (url, qsParams) => {
    return qsParams ? url + '?' + qs.stringify(qsParams, { indices: false }) : url;
};

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export function fetchJSON(url, params = {}) {
    //need to set the auth header
    params.credentials = params.credentials ? params.credentials : 'same-origin';
    return fetch(url, params)
      .then(checkStatus)
      .then((response) => response.json());
}

export function fetchGet(url, qsParams) {
    return fetchJSON(createUrl(url, qsParams));
}

