import Cookies from 'js-cookie';

const timeOffsetSeconds = 'timeOffsetSeconds';

export class TimezoneOffsetCookie {
    constructor() {
        const offsetSeconds = -new Date().getTimezoneOffset()*60; //offset in seconds

        if(!Cookies.get(timeOffsetSeconds)) {
            console.log("setting time zone offset cookie: ");
            console.log(offsetSeconds);
            Cookies.set(timeOffsetSeconds, offsetSeconds);
        }

    }
}
