export function forEachElement(element, selector, func) {
    Array.prototype.forEach.call(element.querySelectorAll(selector), (element, i) => {
        func(element, i);
    });
};

export function addClass(element, className) {
    element.classList ? element.classList.add(className) :  element.className += ' ' + className;
}

export function removeClass(element, className) {
    if (element.classList) {
        element.classList.remove(className);
    }
    else {
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
}

export function closest(element, selector) {

    //this polyfill will support closest for IE9+
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector ||
                                    Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
        Element.prototype.closest = function(s) {
            var el = this;
            if (!document.documentElement.contains(el)) return null;
            do {
                if (el.matches(s)) return el;
                el = el.parentElement || el.parentNode;
            } while (el !== null && el.nodeType === 1);
            return null;
        };
    }

    return element.closest(selector);
}

export function ajax(url, method, data, success, error, onFinally) {

    var request = new XMLHttpRequest();
    request.open(method, url, true);
    request.setRequestHeader('Content-Type', 'application/json');

    var isSuccess = false;

    request.onload = () => {
        if (request.status >= 200 && request.status < 400) {
            isSuccess = true;
            success(request, request.responseText);
        } else {
            error(request, request.responseText);
        }

        if(onFinally) {
            onFinally(request, request.responseText, isSuccess);
        }
    };

    request.onerror = (e) => {
        // There was a connection error of some sort
        console.error("unexpected error making request, status code: " + request.status);
        error({errors: [{message: "unexpected error: status code", request: request }]});
    };

    data ? request.send(JSON.stringify(data)) : request.send();

};

export function post(url, data, success, error) {
    ajax(url, 'POST', data, success, error);
};

export function get(url, success, error) {
    ajax(url, 'GET', null, success, error);
};

export function ajaxError(response) {
    console.error(response);
};

export function dispatchCustomEvent(element, name, detail) {
    //https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Creating_and_triggering_events#The_old-fashioned_way

    // Create the event.
    var event = document.createEvent('Event');

    // Define that the event name is 'build'.
    event.initEvent(name, true, true);
    event.detail = detail;
    // target can be any Element or other EventTarget.
    element.dispatchEvent(event);
};

export function findLabelForElement(element) {

    var label = null;

    var elementId = element.getAttribute('id');
    if(elementId) {
        forEachElement(element, "label[for='" + elementId + "']", function (matchingLabel,i) {
            label = matchingLabel;
        });
    }
    if(!label) {
     label = element.closest('label');
    }
    return label;
};

// ==================== /util functions =====================