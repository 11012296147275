import $ from 'jquery';

export class PublishForm {
    constructor(vanillaModal) {

        document.addEventListener('form-gadget-post-submit', function (event) {
          console.log("POST SUBMIT - POST SUBMIT - POST SUBMIT");
          console.log(event);

          $(event.detail.form).filter(".publish-form").each((i,form) => {

              if(event.detail.request.status == 402) {
                alert("got the 402 error");
                vanillaModal.open("#create-subscription-modal")
              } else if(event.detail.request.status == 200) {
                vanillaModal.close();
              } else {
                alert("TODO: not 402 and not 200, some kind of internal error occured");
              }
//            vanillaModal.close();
          });

        }, false);

    }
}
