import $ from 'jquery';


export class VimeoVideos {
    constructor() {
        console.log($(".vimeo-video-container").length);
        if ($(".vimeo-video-container").length > 0) {
            const resizeVideos = () => {
                $(".vimeo-video-container").each((i,e) => {
                    const $container = $(e);
                    const $iframe = $(e).find("iframe");

                    console.log('$container.width()');
                    console.log($container.width());

                    console.log('$iframe.width()');
                    console.log($iframe.width());

                    const ratio = ($iframe.height()/$iframe.width());


                    if($container.width() < 1080) {
                        $iframe.width($container.width());
                    } else {
                        $iframe.width(1080);
                    }
                    $iframe.height(ratio*$iframe.width());

                });
            };

            $(window).on('resize', resizeVideos);
            resizeVideos();

        }
    }
}


