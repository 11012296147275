import $ from 'jquery';

export class ErrorPage {
    constructor() {
        $('a.go-back').on('click', (event) => {
            window.history.back();
        });
    }
}

