import $ from 'jquery';
import qs from 'qs';

import autoshrink from './autoshrink/autoshrink';
$.fn.autoshrink = autoshrink;

import autosize from 'autosize';

import {getValidation} from './api/FormIntegrationAPI';

const INTEGRATION_VALIDATION_ERROR_CLASS_NAME = "integration-validation-error";

const FIELD_MAPPING_FORM_SELECTOR = "form.form-integration-fields-mapping";
const URL_MAPPING_FORM_SELECTOR = "form.form-integration-url-mapping";

const addAuthenticationErrorMessage = () => {
    $(".authentication-wrapper .logo").before("<div style='color: red' class='" + INTEGRATION_VALIDATION_ERROR_CLASS_NAME + "'>Not Authenticated</div>");
}

const addFormErrorMessages = (formElement, errorMap) => {
    const $form = $(formElement);
    $form.find("." + INTEGRATION_VALIDATION_ERROR_CLASS_NAME).remove();
    //here we're going to go through the error values and add them as necessary
    Object.keys(errorMap).forEach((key, index) => {
        console.log(`key: ${key}`);
        const $inputElement = $form.find("textarea[name=" + key + "] + span, input[name=" + key + "] + span");
        $inputElement.parent().parent().find(".errors").append("<div class='" + INTEGRATION_VALIDATION_ERROR_CLASS_NAME + "' style='color: red;'>*" + errorMap[key].value + "</div>");
        console.log($inputElement.length);
        if($inputElement.length > 0) {
            $inputElement.parents('form').first().find('.form-gadget-form-error').text("*validation error(s), see above.");
        }
    });
}

const validateFormIntegration = (formId, formIntegrationId) => {

    getValidation(formId, formIntegrationId).then((response) => {
        console.log("retrieved form field validations");
        console.log(response);
        if(response.authentication && response.authentication.authentication) {
            addAuthenticationErrorMessage();
        }

        if(response.formFieldMapping && Object.keys(response.formFieldMapping).length !== 0) {
            addFormErrorMessages($(FIELD_MAPPING_FORM_SELECTOR), response.formFieldMapping);
        }

        if(response.formUrlMapping && Object.keys(response.formUrlMapping).length !== 0) {
            addFormErrorMessages($(URL_MAPPING_FORM_SELECTOR), response.formUrlMapping);
        }

    });
}

export class FormIntegration {
    constructor() {

        document.addEventListener('form-gadget-post-submit', function (event) {
            $(event.detail.form).filter(".form-integration-fields-mapping, .form-integration-url-mapping").each((i,form) => {
                if(event.detail.request.status == 200) {
                    window.location.reload();
                } else if (event.detail.request.status == 400) {
                    const $form = $(event.detail.form);
                    addFormErrorMessages($form, event.detail.response);
                }
            });
        });

        if($("body.integration").length > 0) {
            validateFormIntegration(global.formId, global.formIntegrationId);
            autosize($("textarea"));
            $(".field-row label").autoshrink()
        }
    }
}


