import {forEachElement, addClass, removeClass, findLabelForElement} from './Utilities';

export var PRE_FIRST_SUBMISSION = "data-prefirstsubmission";

export var INVALID_REQUIRED_CLASS = 'invalid-required';

// ==================== validate functions ======================
export function validEmail(element) {
    var email = element && element.value ? element.value : "";
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export function validRequired(input) {

    if(input && input.value) {
        switch(input.type) {
            case "checkbox":
            case "radio":
                var name = input.name;
                //need to get all the radio buttons or checkboxes that belong with this one. Name is the thing that binds them
                var oneIsChecked = false;
                forEachElement(document, 'input[name="'+ input.name +'"]', function(e) {
                    if(e.checked) {
                        oneIsChecked = true;
                    }
                });
                return oneIsChecked;

            default:
                return String(input.value).trim() !== "";
        }
    }
    return false;
};

function addElementValidationClass(element, className) {
    addClass(element, className);
    var label = findLabelForElement(element);
    if(label) {
        addClass(label, className);
    }
}

function removeElementValidationClass(element, className) {
    removeClass(element, className);
    var label = findLabelForElement(element);
    if(label) {
        removeClass(label, className);
    }
}

export function changeSubmitDisabled(formElement, allValid) {
    if(formElement.hasAttribute(PRE_FIRST_SUBMISSION)) {
        return;
    }

    forEachElement(formElement,
        'input[type="submit"], button[type="submit"], button:not([type])',
        function (e,i) {
            !!allValid ? e.disabled = false : e.disabled = true;
        }
    );
}

export function validateForm(formElement) {

    if(formElement.hasAttribute(PRE_FIRST_SUBMISSION)) {
        return;
    }

    var allValid = true;

    forEachElement(formElement,'[data-validate]',
        function (element, i) {
            var validations = element.hasAttribute('data-validate') ? element.getAttribute('data-validate').split(" ") : [];

            Array.prototype.forEach.call(validations,
                function(validation,i) {
                    switch (validation) {
                        case 'required':
                            if(!validRequired(element)) {
                                allValid = false;
                                addElementValidationClass(element, INVALID_REQUIRED_CLASS);
                            } else {
                                removeElementValidationClass(element, INVALID_REQUIRED_CLASS);
                            }
                            break;
                        case 'email':
                           if(!validEmail(element)) {
                                allValid = false;
                                addElementValidationClass(element, 'invalid-email')
                           } else {
                                removeElementValidationClass(element, 'invalid-email');
                           }
                           break;
                    }
            });

        }
    );

    return allValid;
}


// ==================== /validate functions =====================
